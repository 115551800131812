@import 'scss/Colors.scss';

.recent-docs {
  &--tenant {
    background-color: white;
    padding: 16px 25px;
    border-radius: 5px;
  }
}

.recent-docs__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & h1 {
    margin: 10px 0px;
  }
  & h2 {
    margin: 10px 0px;
    color: $dark-grey;
  }
  & div {
    display: flex;
    align-items: center;
    color: #392632;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap;
  }
  & svg {
    margin-right: 5px;
  }
}

@mixin recent-line {
  display: flex;
  align-items: center;
  border-top: 1px solid #d2d0cc;
  margin: auto;
}

.agree-line {
  @include recent-line;
  font-size: 16px;
  padding: 18px 0px;
  &__id {
    margin-left: 10px;
    color: #636363;
    justify-self: flex-start;
    cursor: pointer;
  }
  &__tenant {
    margin-left: 24px;
    font-weight: 600;
    color: #3b3b3b;
    justify-self: flex-start;
    margin-right: auto;
  }
}

.doc-line {
  @include recent-line;
  font-size: 14px;
  margin-left: 30px;
  padding: 14px 0px;
  &__name {
    color: #392632;
    font-weight: 600;
    margin-left: 10px;
    margin-right: auto;
    cursor: pointer;
  }
}

.tenant-comented {
  background-color: #8161aa;
  padding: 2px 8px;
  border-radius: 4px;
  margin-right: 14px;
  color: white;
  font-weight: 300;
  font-size: 12px;
  position: relative;
  &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    right: -10px;
    top: 6px;
    border: 5px solid transparent;
    border-left-color: #8161aa;
  }
}

.landlord-responded {
  background-color: #6bcbb4;
  padding: 2px 8px;
  border-radius: 4px;
  margin-right: 14px;
  color: white;
  font-weight: 300;
  font-size: 12px;
  position: relative;
  &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    right: -10px;
    top: 6px;
    border: 5px solid transparent;
    border-left-color: #6bcbb4;
  }
}

.doc-status {
  width: fit-content;
  padding: 2px 8px;
  border-radius: 34px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  &--negotiation {
    background-color: $negotiations;
    color: $negotiations-text;
  }
  &--received {
    background-color: $negotiations;
    color: $negotiations-text;
  }
  &--draft {
    background-color: $draft;
    color: $draft-text;
  }
  &--active {
    background-color: $accepted;
    color: $accepted-text;
  }
}
