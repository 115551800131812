@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';
@import 'scss/Workspace.scss';

.registration {
  height: calc(100vh - 60px);

  .registration__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: inherit;
    position: relative;
    top: -100px;
    width: 550px;
    margin: 0 auto;
  }

  .body {
    text-align: center;
    width: 387px;
  }

  .MuiFormGroup-root {
    margin-bottom: 10px;
  }

  .button--primary {
    width: 100%;
    padding: 0;
    margin-top: 2px;
    height: 43px;
  }

  .input__section__label {
    display: none;
  }

  .registration__links {
    display: flex;
    justify-content: space-between;
    margin-top: 33px;
  }

  .registration__link {
    text-align: left;
    color: $hover;
    cursor: pointer;
    font-size: 13px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .navigation__to-section-link {
    display: none;
  }

  .navigation__right {
    display: none;
  }

  .input__section.large {
    width: unset;
  }
}

// TODO divide to a separate file as a globaly reusable style
.text-invalid {
  color: $red-invalid;
  text-align: left;
  margin: 5px 0 0 0;
}

.text-success {
  text-align: left;
  margin: 5px 0 0 0;
  color: $accepted-text;

  &--big {
    font-size: 20px;
  }
}
