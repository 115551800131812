@import 'scss/Colors.scss';

.arrow-back {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  background-color: white;
  border-left: 1px solid $lighter-grey;
  cursor: pointer;
  &:hover path {
    fill: $hover;
  }
}
