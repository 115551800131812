@import '../../../../../scss/Colors.scss';

.rowExpanded {
  &__button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 20px;
    cursor: pointer;
    &__text {
      margin-left: 5px;
      letter-spacing: 0.02em;
      color: $primary-navy;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    }
  }
}

.rowExpanded__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background: white;
}

.rowExpanded__details__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.rowExpanded__detailColumn {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10px;
  margin-bottom: 10px;
  &:first-child {
    margin-left: 50px;
  }
}

.rowExpanded__detailColumn__item {
  display: flex;
  flex-direction: row;
  font-style: normal;
  &__header {
    display: inline-flex;
    align-items: flex-end;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
  &__data {
    display: inline-flex;
    align-items: flex-end;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}

.rowExpanded__toolbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid $row-divider;
  &__left {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 50px;
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      margin-right: 32px;
      cursor: pointer;
      &__text {
        margin-left: 5px;
        letter-spacing: 0.02em;
        color: $primary-navy;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
  &__right {
    display: flex;
    margin-right: 20px;
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      cursor: pointer;
      &__text {
        margin-left: 5px;
        letter-spacing: 0.02em;
        color: $primary-navy;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}

.rowExpanded__documentList__container {
  padding: 0px 0px 0px 50px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $row-divider;
}

.documentRow {
  // padding: 14px 0px 14px 0px;
  &:not(:last-child) {
    border-bottom: 1px solid $row-divider;
  }
  &__acceptedDate {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: $grey;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 25px;
    &__options {
      margin-left: 25px;
    }
    &__edit {
      margin-right: 16px;
    }
  }
  &__status {
    &--inforce {
      width: fit-content;
      background-color: $accepted;
      padding: 5px 10px 5px 10px;
      border-radius: 34px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $accepted-text;
    }
    &--negotiating {
      width: fit-content;
      background-color: $negotiations;
      padding: 5px 10px 5px 10px;
      border-radius: 34px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $negotiations-text;
    }
    &--draft {
      width: fit-content;
      background-color: $draft;
      padding: 5px 10px 5px 10px;
      border-radius: 34px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $draft-text;
    }
    &--awaitsyoursigniture {
      width: fit-content;
      background-color: $signed;
      padding: 5px 10px 5px 10px;
      border-radius: 34px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $signed-text;
    }
    &--sent {
      width: fit-content;
      background-color: $negotiations;
      padding: 5px 10px 5px 10px;
      border-radius: 34px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $negotiations-text;
    }
    &--signedbytenant {
      width: fit-content;
      background-color: $signed;
      padding: 5px 10px 5px 10px;
      // opacity: 0.7;
      border-radius: 34px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $signed-text;
    }
    &--active {
      width: fit-content;
      background-color: $accepted;
      padding: 5px 10px 5px 10px;
      // opacity: 0.7;
      border-radius: 34px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $accepted-text;
    }
  }
}
