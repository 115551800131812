@import '../../../scss/Colors.scss';

.root {
  &:hover {
    background-color: 'transparent';
  }
}

.icon {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
  // $root.Mui-focusVisible & {
  //   outline: 2px auto rgba(19,124,189,.6);
  //   outline-offset: 2;
  // }
  input:hover ~ & {
    background-color: '#ebf1f5';
  }
  input:disabled ~ & {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
  }
}
.checked-icon {
  background-color: $primary-navy;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
  &:before {
    display: block;
    width: 16px;
    height: 16px;
    background-image: radial-gradient(#fff, #fff 28%, transparent 32%);
    content: '';
  }
  input:hover ~ & {
    background-color: $primary-navy;
  }
}
