@import 'scss/Fonts.scss';
@import 'scss/Colors.scss';
@import 'scss/Workspace.scss';

.management__container {
  font-family: $mukta;
}

.management__body__container {
  display: flex;
  flex-direction: column;
  padding-left: 45px;
  padding-right: 45px;
  margin-top: $top-nav-height;
}

.management__body__toolbar {
  margin-top: 32px;
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $header-divider;
  &-buttons {
    display: flex;
  }
  .button--primary {
    height: 43px;
    margin: 0 0.5px;
  }
}

.management__body__header__container {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}

.management__body__header__text {
  &--large {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    color: $dark-grey;
  }
  &--small {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: $subtle;
  }
}
