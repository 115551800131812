@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';

.select__section {
  display: flex;
  font-family: $mukta !important;
  border-bottom: 1px solid $input-divider;
  height: 42px;
  &__wrapper {
    position: relative;
    cursor: pointer;
    &:after {
      content: url('../../../images/chevron_down.svg');
      position: absolute;
      top: 11px;
      right: 9px;
      pointer-events: none;
      cursor: pointer;
    }
    &--active {
      position: relative;
      cursor: pointer;
      &:after {
        content: url('../../../images/chevron_up.svg');
        position: absolute;
        top: 11px;
        right: 9px;
        pointer-events: none;
        cursor: pointer;
      }
    }
  }
  &.large {
    width: 549px;
  }
  &.medium {
    width: 436px;
  }
  &__label {
    display: flex;
    align-items: center;
    font-family: $mukta !important;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: $dark-grey;
    &.large {
      width: 162px;
    }
    &.medium {
      width: 162px;
    }
  }
  &__field {
    position: relative;
    height: 40px;
    width: 100%;
    font-family: $mukta !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.025em;
    border: none;
    appearance: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &--gray {
      height: 40px;
      width: 100%;
      font-family: $mukta !important;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.025em;
      background-color: #fcfaf8;
      border: none;
    }
  }
  &__select {
    position: absolute;
    width: 100%;
    margin: 0;
    padding-inline-start: 0;
    background-color: #fff;
    z-index: 9999999;
    border: 0.5px solid #ddd;
    max-height: 300px;
    overflow-y: scroll;
    // &--gray{
    //   margin: 0;
    //   padding-inline-start: 0;
    //   background-color: #FCFAF8;
    //   z-index: 9999999;
    // }
  }
  &__selected {
    margin: 0;
    padding-inline-start: 13px;
    max-width: 100%;
    height: 100%;
    background-color: #fff;
    font-weight: bold;
    line-height: 40px;
    border-bottom: 1px solid #ddd;
    z-index: -1;
    &--gray {
      margin: 0;
      padding-inline-start: 13px;
      max-width: 100%;
      height: 100%;
      font-weight: bold;
      line-height: 40px;
      background-color: #fcfaf8;
      z-index: -1;
    }
  }
  &__item {
    height: 40px;
    padding-inline-start: 13px;
    list-style: none;
    line-height: 40px;
    z-index: 999999;
    &:hover {
      background-color: $button-hover;
    }
  }
  &__option {
    margin: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 999999;
  }
}
.large {
  width: 387px;
}
.medium {
  width: 274px;
}
