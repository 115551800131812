@import 'scss/Colors.scss';

.modalAddTenants__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 583px;
  height: min-content;
  background-color: $background-grey2;
  border-radius: 5px;
  padding: 32px;
  outline: white;
  h1 {
    color: $dark-grey;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
  }
  .submitButton {
    margin-top: 32px;
  }
}
