@import './../../../scss/Colors.scss';
@import './../../../scss/Fonts.scss';

.inform__container {
  // width: max-content;
  height: 81px;
  background: #ffffff;
  border-radius: 5px;
  padding: 0px 25px 0px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  .inform__body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .normal {
      svg {
        width: 38px;
        height: 38px;
      }
      path {
        fill: $primary-navy;
      }
    }
    .success {
      svg {
        width: 38px;
        height: 38px;
      }
      path {
        fill: $jade-green;
      }
    }
    .failure {
      path {
        fill: $red-invalid;
      }
    }
    &__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .normal {
        svg {
          width: 38px;
          height: 38px;
        }
        path {
          fill: $primary-navy;
        }
      }
      .success {
        svg {
          width: 38px;
          height: 38px;
        }
        path {
          fill: $jade-green;
        }
      }
      .failure {
        path {
          fill: $red-invalid;
        }
      }
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__text {
      margin-left: 25px;
      color: $dark-grey;
      font-family: $mukta;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
