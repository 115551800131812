@import '../../../scss/Colors.scss';
@import '../../../scss/Fonts.scss';

.input__section {
  display: inline-flex;
  font-family: $mukta !important;
  border-bottom: 1px solid $input-divider;
  height: 42px;
  &--wide {
    width: 100%;
  }
  &.large {
    width: 549px;
  }
  &.medium {
    width: 436px;
  }

  & input[type='date']::-webkit-inner-spin-button,
  & input[type='date']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='date']::-webkit-calendar-picker-indicator {
    margin: 0px 5px;
  }
}

.input__section__label {
  display: flex;
  align-items: center;
  font-family: $mukta !important;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: $dark-grey;
  &--wide {
    display: flex;
    width: 100px;
    align-items: center;
    font-family: $mukta !important;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: $dark-grey;
    &.large {
      min-width: 162px;
      max-width: 162px;
    }
    &.medium {
      min-width: 162px;
      max-width: 162px;
    }
  }
  &.large {
    min-width: 162px;
    max-width: 162px;
  }
  &.medium {
    min-width: 162px;
    max-width: 162px;
  }
}

.input__section__field {
  box-sizing: border-box;
  height: 40px;
  padding-left: 13px;
  font-family: $mukta !important;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.025em;
  border: none;
  font-weight: bold;
  &--wide {
    height: 40px;
    width: 100%;
    padding-left: 13px;
    font-family: $mukta !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.025em;
    border: none;
  }
  &--gray {
    height: 40px;
    width: 100%;
    padding-left: 13px;
    font-family: $mukta !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.025em;
    background-color: #fcfaf8;
    border: none;
  }
  &.large {
    min-width: 387px;
    max-width: 387px;
  }
  &.medium {
    min-width: 274px;
    max-width: 274px;
  }
}
