@import '../../../scss/Colors.scss';
@import '../../../scss/Fonts.scss';

.textArea__section {
  display: inline-flex;
  font-family: $mukta !important;
  border-bottom: 1px solid $input-divider;
  // height: 42px;
  &.large {
    width: 549px;
  }
  &.medium {
    width: 436px;
  }
}

.textArea__section__label {
  display: flex;
  // flex: 50%;
  align-items: center;
  font-family: $mukta !important;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: $dark-grey;
  &.large {
    min-width: 162px;
    max-width: 162px;
  }
  &.medium {
    min-width: 162px;
    max-width: 162px;
  }
}

.textArea__section__field {
  // flex: 50%;
  // height: 40px;
  // width: 274px;
  padding-left: 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.025em;
  border: none;
  resize: none;
  font-family: $mukta !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: $dark-grey;
  &.large {
    width: 387px;
  }
  &.medium {
    width: 274px;
  }
}
