@import 'scss/Fonts.scss';
@import 'scss/Colors.scss';

.lp-section {
  margin-top: 25px;
  min-width: 436px;
  &__title {
    font-family: $mukta;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: $dark-grey;
    border-bottom: 1px solid $divider;
  }
}
