@import '../../../../../scss/Colors.scss';
@import '../../../../../scss/Fonts.scss';

.modalHelp {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f7f5;
    border-radius: 5px;
    padding: 0px 50px 0px 50px;
    width: 583px;
    height: 200px;
    z-index: 99999999;
  }
  &__title {
    text-align: center;
    font-family: $mukta;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    color: $dark-grey;
    margin: 0;
  }
  &__buttonsContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 583px;
  }
}
