@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateY(300%);
    transform: translateY(300%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes slide-in {
  0% {
    -webkit-transform: translateY(300%);
    transform: translateY(300%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(300%);
    transform: translateY(300%);
  }
}
@keyframes slide-out {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(300%);
    transform: translateY(300%);
  }
}
