@import './Colors.scss';
@import './Fonts.scss';

body,
html,
#root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f3f2;
  overflow-x: hidden;
}

.formatButton {
  color: #aaa !important;
  &--active {
    color: white !important;
  }
}

.formControl {
  align-items: center !important;
  color: white !important;
  width: 100% !important;
}

.formLabel {
  color: white !important;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  &__close {
    cursor: pointer;
    position: absolute;
    top: 1vh;
    right: 1vw;
  }
}

.h2 {
  font-size: 22px;
}
