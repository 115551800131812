@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(1.3);
  }
  10% {
    opacity: 0;
    transform: scale(1.3);
  }

  80% {
    opacity: 1;
    transform: scale(1);
  }
  81% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
// @keyframes fade-in {
//   from {
//     opacity: 0;
//     transform: scale(1.3);
//   }
//   to {
//     opacity: 1;
//     transform: scale(1);
//   }
// }

.slide-img {
  animation-name: fade-in;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes slide-in {
  0% {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
}
@keyframes slide-out {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
}
