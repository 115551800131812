@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';
@import 'scss/Workspace.scss';

.page__container {
  font-family: $mukta;
}

.leaseManagement__container {
  display: flex;
  flex-direction: column;
  padding-left: 45px;
  padding-right: 45px;
  // height: calc(100% - 60px);
}

.management-header__container {
  display: flex;
  flex-direction: column;
  // margin-top: 32px;
}

.header__secondary__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
  border-bottom: 1px solid $divider;
}

.header__text {
  &--large {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: $dark-grey;
  }
  &--medium {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    color: $dark-grey;
  }
  &--small {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: $subtle;
  }
}

.sections__container {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
  height: 100%;
}
.section {
  display: flex;
  flex-basis: 100%;
  flex: 1;
  flex-direction: column;

  align-items: center;
  height: 100%;
  &:first-child {
    border-right: 1px solid $divider;
  }
  &__description {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 436px;
    &__title {
      display: flex;
      align-items: center;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $dark-grey;
    }
    &__text {
      margin-top: 14px;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      word-wrap: break-word;
      color: $grey;
    }
  }
}

.templatesList {
  flex-basis: 100%;
  width: 100%;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  &__item {
    padding: 32px;
    margin-top: 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 208px;
    &__existingTemplate {
      background-image: url(../../../../images/papers.svg);
      width: 154px;
      height: 185px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(0px 0px 8px rgba(99, 99, 99, 0.13));
    }
    &__newTemplate {
      background-image: url(../../../../images/fake_papers.svg);
      width: 154px;
      height: 185px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    &__existingDoc {
      background-image: url(../../../../images/paper.svg);
      width: 114px;
      height: 142px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(0px 0px 8px rgba(99, 99, 99, 0.13));
    }
    &__newDoc {
      background-image: url(../../../../images/fake_paper.svg);
      width: 114px;
      height: 142px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    &__title {
      margin-top: 32px;
      display: flex;
      align-items: center;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $dark-grey;
    }
    &__text {
      display: flex;
      align-items: center;
      text-align: center;
      // margin-top: 14px;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      word-wrap: break-word;
      color: $grey;
    }
  }
}

.section__button--primary {
  margin-top: 32px;
}

.removeTemplate__button {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  &--document {
    position: absolute;
    top: 0px;
    right: 20px;
    z-index: 100;
    cursor: pointer;
    &:hover {
      path {
        fill: $red-invalid;
      }
    }
  }
  cursor: pointer;
  &:hover {
    path {
      fill: $red-invalid;
    }
  }
}
