@import 'scss/Colors.scss';

@mixin status {
  width: fit-content;
  padding: 2px 8px;
  border-radius: 34px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.table {
  display: block;
  border-spacing: 0;
  border: none;
  width: 100%;
  text-align: left;
  .tr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding: 0.5rem 0px 0.5rem 0px;
    &--expanded {
      background-color: white;
    }
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th {
    height: 40px;
    min-width: 30;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid $row-divider;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
  .td {
    display: flex;
    min-width: 30;
    //     width: 150,
    // max-width: 300px;
    height: 62px;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid $row-divider;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    &--expanded {
      background-color: white;
    }
    :last-child {
      border-right: 0;
    }
  }
}

.table__row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.resizer {
  display: inline-block;
  width: 3px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  &:hover {
    background: $primary-navy;
  }
  &.isResizing {
    background: $primary-navy;
  }
}

.management__table__row {
  &__leaseID {
    color: $primary-navy;
    font-style: normal;
    font-weight: bold !important;
    font-size: 16px;
    cursor: pointer;
  }
  &__tenant {
    color: $dark-grey;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  &__cell__status__container {
    box-sizing: border-box;
    // padding: 0 8px !important;
    display: flex;
    // justify-content: center;
    // min-width: 80px;
    // max-width: 150px !important;
  }
  &__status {
    width: fit-content;
    padding: 5px 10px 5px 10px;
    // opacity: 0.7;
    border-radius: 34px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    // line-height: 18px;
    &--inforce {
      background-color: $accepted;
      color: $accepted-text;
      @include status;
    }
    &--negotiating {
      background-color: $negotiations;
      color: $negotiations-text;
      @include status;
    }
    &--draft {
      background-color: $draft;
      color: $draft-text;
      @include status;
    }
    &--sent {
      background-color: $negotiations;
      color: $negotiations-text;
      @include status;
    }
    &--received {
      background-color: $negotiations;
      color: $negotiations-text;
      @include status;
    }
    &--signedbytenant {
      background-color: $signed;
      color: $signed-text;
      @include status;
    }
    &--active {
      background-color: $accepted;
      color: $accepted-text;
      @include status;
    }
  }
}
