@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';

.profile {
  font-family: $mukta;
  height: 100vh;
  &__body {
    margin-top: 92px;
    display: flex;
    flex-direction: column;
    padding-left: 45px;
    padding-right: 45px;
    height: calc(100% - 60px);
  }
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  &__text {
    &--large {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      color: $dark-grey;
    }
    &--medium {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 33px;
      color: $dark-grey;
    }
    &--small {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: $subtle;
    }
  }
}

.sections {
  display: flex;
  flex-direction: row;
  border-top: 1px solid $divider;
  margin-top: 8px;
  width: 100%;
  height: 100%;
  &__section {
    margin-top: 16px;
    display: flex;
    flex-basis: 100%;
    flex: 1;
    flex-direction: column;
    height: 100%;
    width: 400px;
  }
  &__profile {
    margin: 6.5px 75px 0px 44px;
    width: 105px;
    &__link {
      margin-top: 15px;
      font-weight: bold;
      a {
        font-size: 13px;
        text-decoration: none;
        color: $primary-navy;
      }
    }
  }
}

.data {
  &__container {
    width: 600px;
    &__info {
      height: 22px;
      padding: 5px 0 16px 35%;
    }
    &__paragraph {
      margin: 0;
      font-size: 12px;
    }
  }
}

.createNewTemplate {
  padding-top: 75px;
  cursor: pointer;
}
