@import 'scss/Fonts.scss';

.MuiFormLabel-root {
  font-family: $mukta !important;
}

.MuiTypography-root {
  font-family: $mukta !important;
}

.filters {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #d2d0cc;

  &__radios {
    flex-grow: 1;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    legend {
      margin-top: 8px;
    }
  }
  &__radio-container {
    display: flex;
    flex-direction: column;
  }
  &__columns {
    flex-grow: 2;
    display: flex;
    justify-content: space-around;
  }
  &__column {
    display: flex;
    flex-direction: column;
  }
}
