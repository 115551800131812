@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';
@import 'scss/Workspace.scss';

.invite-tenant {
  height: calc(100vh - 60px);

  .invite-tenant__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: inherit;
    position: relative;
    top: -100px;
    width: 550px;
    margin: 0 auto;
  }

  .header {
    margin-bottom: 12px;
  }

  .body {
    text-align: center;
    width: 387px;
  }

  .button--primary {
    width: 100%;
    padding: 0;
    margin-top: 2px;
    height: 43px;
  }

  .input__section__label {
    display: none;
  }

  .invite-tenant__links {
    display: flex;
    justify-content: space-between;
    margin-top: 33px;
  }

  .invite-tenant__link {
    text-align: left;
    color: $hover;
    cursor: pointer;
    font-size: 13px;

    &:hover {
      text-decoration: underline;
    }
  }
}
