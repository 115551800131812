@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';
@import 'scss/Workspace.scss';

.settings {
  &__header {
    border-top: none;
    border-bottom: 1px solid $divider;
    width: 100%;
  }
  &__body {
    border-top: none;
    flex-direction: column;
  }
  &__title {
    flex-direction: row !important;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
  }
  &__info {
    font-size: 14px;
    margin: 0;
  }
  &__displayed-columns {
    width: 100%;
    border-bottom: 1px solid $grey;
  }
  &__columns-list {
    margin-top: 40px;
    padding-inline-start: 0px;
    display: flex;
    list-style: none;
    justify-content: space-between;
  }
  &__columns-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 6px;
      cursor: pointer;
    }
  }
  &__remove {
    path {
      fill: $red-invalid;
    }
  }
  &__radios {
    margin: 15px 0 0 230px !important;
  }
  &__radio-label {
    margin: 0 0 0 24px;
  }
}
