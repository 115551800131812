@import '../../../scss/Colors.scss';
@import '../../../scss/Fonts.scss';

.button {
  &--primary {
    width: min-content;
    background-color: $primary-navy;
    cursor: pointer;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    &:active {
      background-color: $primary-navy;
    }
    &:hover {
      background-color: $button-hover;
    }
    &:focus {
      background-color: $button-hover;
      box-shadow: 0px 0px 9px rgba(44, 56, 138, 0.37);
    }
    &:disabled {
      background-color: $button-disabled;
    }
    path {
      fill: white;
    }
    &__text {
      width: max-content;
      margin-left: 10px;
      font-family: $mukta;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.02em;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
  }
  &--secondary {
    width: min-content;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    &:hover &__text {
      color: $button-hover;
    }
    &--whitebg {
      width: min-content;
      cursor: pointer;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 10px 3px 10px;
      background-color: white;
      box-shadow: 0px 0px 4px rgba(44, 56, 138, 0.38);
      &:active {
        color: $primary-navy;
      }
      &:hover {
        color: $primary-navy;
        path {
          fill: $primary-navy;
        }
      }
      &:focus {
        color: $primary-navy;
        path {
          fill: $primary-navy;
        }
        text-shadow: 0px 0px 9px rgba(44, 56, 138, 0.37);
      }
      &:disabled {
        path {
          fill: $button-disabled;
        }
        color: $button-disabled;
      }
      path {
        fill: $primary-navy;
      }
      &__text {
        width: max-content;
        margin-left: 10px;
        font-family: $mukta;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.02em;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary-navy;
      }
    }
    &--red {
      width: min-content;
      cursor: pointer;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:active {
        color: $red-invalid;
      }
      &:hover {
        color: $red-invalid;
        path {
          fill: $red-invalid;
        }
      }
      &:focus {
        color: $red-invalid;
        path {
          fill: $red-invalid;
        }
        text-shadow: 0px 0px 9px rgba(44, 56, 138, 0.37);
      }
      &:disabled {
        path {
          fill: $button-disabled;
        }
        color: $button-disabled;
      }
      path {
        fill: $red-invalid;
      }
      &__text {
        width: max-content;
        margin-left: 10px;
        font-family: $mukta;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.02em;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $red-invalid;
        &:hover {
          color: $red-invalid;
        }
      }
    }
    &:active {
      color: $primary-navy;
    }
    &:hover {
      path {
        fill: $button-hover;
      }
    }
    &:focus {
      color: $button-hover;
      path {
        fill: $button-hover;
      }
      text-shadow: 0px 0px 9px rgba(44, 56, 138, 0.37);
    }
    &:disabled {
      path {
        fill: $button-disabled;
      }
      color: $button-disabled;
    }
    path {
      fill: $primary-navy;
    }
    &__text {
      width: max-content;
      margin-left: 10px;
      font-family: $mukta;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.02em;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary-navy;
      &:hover {
        color: $button-hover;
      }
    }
  }
  &--close {
    width: min-content;
    cursor: pointer;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      color: $red-invalid;
    }
    &:hover {
      color: $red-invalid;
      path {
        fill: $red-invalid;
      }
    }
    &:focus {
      color: $red-invalid;
      path {
        fill: $red-invalid;
      }
    }
    &:disabled {
      path {
        fill: $button-disabled;
      }
      color: $button-disabled;
    }
    path {
      fill: $red-invalid;
    }
    &__text {
      width: max-content;
      margin-left: 10px;
      font-family: $mukta;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.02em;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $red-invalid;
    }
  }
  &--decline {
    width: min-content;
    background-color: $red-invalid;
    cursor: pointer;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    // &:active {
    //   background-color: $primary-navy;
    // }
    &:hover {
      background-color: lighten($color: $red-invalid, $amount: 10%);
    }
    // &:focus {
    //   background-color: $button-hover;
    //   box-shadow: 0px 0px 9px rgba(44, 56, 138, 0.37);
    // }
    // &:disabled {
    //   background-color: $button-disabled;
    // }
    path {
      fill: white;
    }
    &__text {
      width: max-content;
      margin-left: 10px;
      font-family: $mukta;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.02em;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
  }
}

.marginLeft {
  margin-left: 20px;
}
.marginRight {
  margin-right: 20px;
}
