@import '../../../scss/Colors.scss';
$text-color: white;

.navigation {
  position: sticky;
  z-index: 1000;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  top: 0px;
  left: 0px;
  padding-right: 10px;
  color: white;
  background-color: $navigation-bg;
  &__left {
    display: flex;
    justify-content: flex-start;
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
  &__logo {
    display: flex;
    height: 60px;
    width: 160px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__divider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1px;
    height: 24px;
    margin-top: 20px;
    border-right: 1px solid $lighter-grey;
  }
  &__to-section-link {
    display: flex;
    // height: 60px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $text-color;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    // margin-left: 36px;
    box-sizing: border-box;
    fill: #b3cea3;
    stroke: #b3cea3;
    // border-bottom: 4px solid $navigation-bg;
    &--active {
      box-sizing: border-box;
      display: flex;
      // height: 60px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: $text-color;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      // margin-left: 36px;
      border-right: 4px solid $text-color;
      fill: $text-color;
      stroke: $text-color;
    }
  }
  &__link {
    &--small {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: $text-color;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      margin-right: 18px;
    }
  }
}

.side-panel {
  position: fixed;
  padding: 60px 0;
  z-index: 900;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  top: 0px;
  left: 0px;
  height: calc(100% - 60px);
  width: 48px;
  color: white;
  background-color: $navigation-bg;
  &__divider {
    min-width: 100%;
    height: 1px;
    background-color: white;
    display: block;
  }
  &__bottom-nav {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
  }
}
