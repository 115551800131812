@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';

.categoryName {
  font-family: $mukta;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $grey;
  width: 208px;
  height: 21px;
  margin-bottom: 14px;
  margin-top: 14px;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 208px;
  height: 240px;
  cursor: pointer;
  &__existingDoc {
    background-image: url(../../../images/paper.svg);
    width: 114px;
    height: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 0px 8px rgba(99, 99, 99, 0.13));
  }
  &__newDoc {
    display: flex;
    flex-direction: column;
    background-image: url(../../../images/fake_paper.svg);
    width: 114px;
    height: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    justify-content: space-evenly;
  }
  &__title {
    margin-top: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    font-family: $mukta;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    color: $dark-grey;
  }
  &__text {
    display: flex;
    margin-top: 14px;
    align-items: center;
    text-align: center;
    font-family: $mukta;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    height: 21px;
    text-align: center;
    word-wrap: break-word;
    color: $grey;
  }
}

.selected {
  border: 2px solid #4f93e2;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: white;
}
.unselected {
  border: 2px solid $divider;
  box-sizing: border-box;
  border-radius: 4px;
}
