@import 'scss/Fonts.scss';
@import 'scss/Colors.scss';

.panel__container {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $lighter-grey;
  height: 100vh;
  padding-top: 22px;
  padding-left: 45px;
  padding-right: 45px;
  background-color: #f4f3f2;
  min-width: 450px;
}

.panel__section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.panel__header {
  margin-bottom: 32px;
  &__title {
    font-family: $mukta;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: $dark-grey;
  }
  &__text {
    margin-top: 14px;
    font-family: $mukta;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: $grey;
  }
}

.panel__section__header {
  display: inline-flex;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b3b3b;
  border-bottom: 1px solid $header-divider;
}

.panel__section__row {
  display: inline-flex;
}

.panel__section__empty {
  flex: 50%;
}

.panel__section__radioButtons {
  flex: 50%;
}

.panel__section__button {
  display: inline-flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    p {
      color: $hover;
    }
    path {
      fill: $hover;
    }
  }
}

.panel__section__button__text {
  margin-left: 10px;
  color: $primary-navy;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}

.lp-section {
  margin-top: 25px;
  min-width: 436px;
  &__title {
    font-family: $mukta;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: $dark-grey;
    border-bottom: 1px solid $divider;
  }
}
