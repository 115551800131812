@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css?family=Chivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;700&display=swap');

$mukta: 'Poppins', sans-serif;
$mukta: 'Mukta', sans-serif;
$chivo: 'Chivo', sans-serif;

:export {
  poppins: $mukta;
  mukta: $mukta;
  chivo: $chivo;
}
