.organization-item {
  margin: 8px;
  padding: 16px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 180px;
  height: 48px;
  cursor: pointer;
  &__selected {
    border: 2px solid #4f93e2;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: white;
  }
  &__unselected {
    border: 2px solid #d2d0cc;
    box-sizing: border-box;
    border-radius: 4px;
  }
  &__selected-agreement {
    margin: 0;
    width: 240px;
    height: 64px;
    border: 2px solid #4f93e2;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: white;
  }
  &__unselected-agreement {
    margin: 0;
    width: 240px;
    height: 64px;
    border: 2px solid #d2d0cc;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
