@import './../../../scss/Colors.scss';
@import './../../../scss/Fonts.scss';

.bottomNavigation__container {
  display: inline-flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  font-family: $mukta;
  border-top: 1px solid $lighter-grey;
  position: fixed;
  bottom: 0px;
  background-color: $background-navigation-beige;
}

.leftArea {
  display: flex;
  align-items: center;
}

.rightArea {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  background-color: white;
  height: 50px;
  width: 160px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrowleft {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  background-color: white;
  border-left: 1px solid $lighter-grey;
  cursor: pointer;
  &:hover path {
    fill: $hover;
  }
}

.bottomNavigation__id {
  color: $primary-navy;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  justify-content: center;
}

.bottomNavigation__text {
  color: $primary-navy;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  justify-content: center;
}

.bottomNavigation__button--primary {
  width: 180px;
  height: 50px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: $primary-navy;
  cursor: pointer;
}

.bottomNavigation__button--primary__icon path {
  fill: #ffffff;
}

.bottomNavigation__button--primary__text {
  margin-left: 10px;
}

.bottomNavigation__button {
  width: 180px;
  height: 50px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-navy;
  cursor: pointer;
}

.bottomNavigation__print__button {
  width: 120px;
  height: 50px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-navy;
  cursor: pointer;
}

.bottomNavigation__button__icon path {
  fill: $primary-navy;
}

.bottomNavigation__button__text {
  margin-left: 10px;
}
