@import 'scss/Colors.scss';

.events {
  display: flex;
  flex-direction: column;
  width: 550px;
  margin-left: 50px;
  // margin-top: 20px;
  padding: 16px 25px;
  height: 100%;
  & h1 {
    margin: 0px;
    font-size: 24px;
    color: $dark-grey;
    border-bottom: solid 1px #d2d0cc;
    padding: 10px 0px;
  }
  &--tenant {
    border-radius: 5px;
    background-color: white;
  }
}

.month-line {
  height: 14px;
  &--first {
    margin-top: 30px;
  }
  p {
    margin-top: -10px;
    margin-left: 64px;
    padding-left: 10px;
    text-transform: uppercase;
    color: #636363;
    font-size: 14px;
    position: relative;
    &::before {
      content: ' ';
      position: absolute;
      left: -8px;
      top: 10px;
      width: 10px;
      height: 30px;
      border-left: 1px solid #d2d0cc;
      border-top: 1px solid #d2d0cc;
    }
  }
}

// .month-line {
//   position: relative;
//   div {
//     display: inline;
//     margin-left: 56px;
//     border-top: solid 1px #D2D0CC;
//     border-left: solid 1px #D2D0CC;
//     padding-right: 10px;
//   }
//   p {
//     position: absolute;
//     top: -24px;
//     display: inline;
//     padding-left: 10px;
//     text-transform: uppercase;
//     color: #636363;
//     font-size: 14px;
//   }
// }

.calendar-card {
  margin: 10px;
  margin-right: 15px;
  display: block;
  & div {
    width: 31px;
    background-color: white;
    box-shadow: 1px 1px #d2d0cc;
    text-align: center;
    margin-top: 3px;
    h3 {
      display: inline;
      font-weight: 600;
      font-size: 16px;
      border-bottom: solid 1px #d2d0cc;
      margin: 0px;
    }
    p {
      font-size: 10px;
      color: #636363;
      margin: 0px;
    }
  }
  & svg {
    position: absolute;
  }
}

//TODO: fix important here
.svg--ending path {
  fill: $event-ending !important;
}

.svg--begins path {
  fill: $event-begins !important;
}

.timeline-day {
  white-space: nowrap;
  display: flex;
  border-radius: 5px;
  &__lines {
    border-left: 1px solid #d2d0cc;
    div {
      display: flex;
      align-items: center;
      padding: 10px;
      padding-bottom: 0px;
      position: relative;
      &:first-child {
        margin-top: 10px;
        &::before {
          content: ' ';
          position: absolute;
          left: -5px;
          top: 17px;
          border-radius: 50%;
          border: 5px solid #d2d0cc;
        }
      }
      &:last-child {
        margin-bottom: 20px;
      }
      & path {
        fill: #636363;
      }
      & > span {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }
  }
  &__lines--highlighted {
    div:first-child {
      &::before {
        border: 5px solid black;
      }
    }
  }

  &__lines--month-end {
    div:last-child {
      margin-bottom: 50px;
    }
  }

  &--highlighted {
    background-color: rgba(79, 147, 226, 0.1);
  }
}

.timeline-status {
  width: fit-content;
  padding: 2px 8px;
  border-radius: 34px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin: 0px 5px;
  &--commented {
    background-color: $comment-bg-dashboard;
    color: $violet;
  }
  &--draft {
    background-color: $draft;
    color: $draft-text;
  }
  &--active {
    background-color: $accepted;
    color: $accepted-text;
  }
  &--responded {
    background-color: #6bcbb4;
    color: white;
  }
}

.timeline-bold {
  color: #392632;
  font-weight: 600;
  margin: 0px 5px;
  &--ending {
    color: $event-ending;
  }
  &--begins {
    color: $event-begins;
  }
}
