@import './../../../../scss/Colors.scss';

.management__body__search__container {
  display: flex;
  flex-direction: row;
  height: 42px;
}

.management__body__search {
  &__searchbar {
    margin-right: 8px;
    display: flex;
    background-color: white;
    color: $dark-grey;
    width: 274px;
    padding-left: 13px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.025em;
    border: none;
    // border-top: 1px solid $input-divider;
  }
  &--active-filters {
    background-color: #fff;
  }
}

.management__body__filtersToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  cursor: pointer;
  &__text {
    margin-left: 10px;
    color: $primary-navy;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.02em;
  }
}
