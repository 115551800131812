.add-document-modal {
  position: fixed;
  margin: auto auto;
  width: 90vw;
  height: 90vh;
  z-index: 9999;
  background: #f4f3f2;
  opacity: 0.2;
  overflow-x: hidden;
}
