$color-primary: #392632;

// Backgrounds
$background-white: #ffffff;
$background-grey: #f4f3f2;
$background-grey2: #f9f7f5;
$navigation-bg: $color-primary;
$background-navigation-beige: #eae9e8;
// Different shades of grey and dividers
$dark-grey: #3b3b3b;
$grey: #636363;
$light-grey: #e4e4e4;
$lighter-grey: #cfcecd;
$input-divider: #eeece8;
$header-divider: #d2d0cc;
$row-divider: #d2d0cc;
$divider: #d2d0cc;
$subtle: #b4b2ae;

// Main app colors
$primary-navy: #392632;
$navy-light: #303d71;
$red-invalid: #d6557c;
$jade-green: #6bcbb4;
$violet: #8161aa;

// Link
$hover: #5667da;

// Buttons
$button-hover: #4f93e2;
$button-disabled: #d2d0cc;

// Status
$accepted: #c3e778;
$accepted-text: #557e00;
$negotiations: #ffa75b;
$negotiations-text: #cc4900;
$draft: #d2d0cc;
$draft-text: #636363;
$signed: #e0c240;
$signed-text: #7e6a12;

//connected field
$field-bg: #e6eaff;

//comment in document content
$comment-bg: rgba(129, 97, 170, 0.2);
$comment-bg-dark: rgba(129, 97, 170, 0.4);

$comment-bg-dashboard: rgba(129, 97, 170, 0.2);
$event-ending: #e24a78;
$event-begins: #1eb08d;
