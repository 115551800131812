@import 'scss/Colors.scss';

.document__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  width: 250px;
  height: 250px;
  text-decoration: none;
  color: $dark-grey;
  font-size: 16px;
  .card {
    display: flex;
    flex-direction: column;
    background-image: url(../../../images/paper.svg);
    width: 114px;
    height: 142px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px rgba(99, 99, 99, 0.13));
  }
  .document__name {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}
