@import 'scss/Colors.scss';

.modalUpdateDocName__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: $background-grey;
  border-radius: 5px;
  .saveButton {
    margin-top: 20px;
  }
}
