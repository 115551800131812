@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';

.modalCreateDocTemplate__container {
  display: flex;
  flex-direction: column;

  background-color: #f9f7f5;
  border-radius: 5px;
  padding: 35px 35px 35px 35px;
  z-index: 99999999;
  outline: white;
  .title {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: $mukta;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: $dark-grey;
  }
  .createButton {
    justify-self: center;
  }
}
