@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';

.modalToUpload {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f7f5;
    border-radius: 5px;
    padding: 25px 50px 25px 50px;
    width: 583px;
    height: 327px;
    z-index: 99999999;
    outline: white;
  }
  &__title {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: $mukta;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: $dark-grey;
  }
  &__input {
    opacity: 0;
    &:focus {
      border: none;
    }
  }
  &__buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 583px;
    margin-top: 32px;
  }
  &__note {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    color: $grey;
  }
}
