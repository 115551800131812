@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';

$top-nav-height: 60px;

.workspace {
  font-family: $mukta;
  height: 100%;
  width: 100vw;
  // overflow: hidden;
  display: flex;
  &--with-navbar {
    height: calc(100vh - 60px);
    margin-top: $top-nav-height;
  }
  &--with-bottombar {
    height: calc(100vh - 50px);
    // height: 100%;
  }
  &--with-both-bars {
    height: calc(100vh - 50px - 60px);
    margin-top: $top-nav-height;
  }
}

.workspace__panel {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-right: 1px solid $lighter-grey;
  height: 100%;
  // max-height:
  padding-top: 22px;
  padding-left: 45px;
  // padding-right: 50px;
  background-color: #f4f3f2;
  min-width: 540px;
  max-width: 570px;
  // position: fixed;
  overflow-y: auto;
  // overflow-x: hidden;
  &--hidden {
    left: -200px;
  }
}

.workspace__area {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  // grid-template-columns: 50% 50%;
  // width: 100%;
  // margin: auto;
  height: 100%;
  overflow-y: auto;
  flex-wrap: wrap;
  flex-grow: 2;
}

.workspace__comments {
  right: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // border-right: 1px solid $lighter-grey;
  height: calc(100% - 110px);
  max-height: 100%;
  padding-top: 22px;
  padding-left: 16px;
  padding-right: 32px;
  background-color: #f4f3f2;
  max-width: 300px;
  min-width: 200px;
  // z-index: 1;
}

:export {
  top-nav-height: $top-nav-height;
}
