@import 'scss/Colors.scss';
@import 'scss/Fonts.scss';

.closeButton {
  position: absolute;
  top: 15px;
  right: 45px;
}

.addDocumentTemplates__topSection {
  &__header {
    &__title {
      margin-bottom: 14px;
      line-height: 1.5rem;
      font-weight: bold;
      font-size: 32px;

      color: $dark-grey;
    }
    &__description {
      margin-bottom: 32px;
      font-weight: 300;
      font-size: 14px;
      line-height: 1.5rem;
      color: $grey;
    }
  }
}

.categoryDivider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1px;
  &__space {
    display: flex;
    height: 21px;
  }
  &__divider {
    display: flex;
    width: 1px;
    height: 240px;
    &__d {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1px;
      height: 200px;
      background-color: $divider;
    }
  }
}

.categoryDividerR {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1px;
  height: 200px;
  margin-top: 55px;
  background-color: $divider;
}
